import React, { useState } from 'react';
import { Select, Typography, Row, Col, Avatar, Card } from 'antd';
import moment from 'moment';
import { useGetCryptoNewsQuery } from '../services/CryptoNewsApi';
import { useGetCryptosQuery } from '../services/CryptoApi';

const { Text, Title } = Typography;
const { Option } = Select;

const demoImage =
	'https://www.bing.com/th?id=OVFT.mpzuVZnv8dwIMRfQGPbOPC&pid=News';

const News = ({ simplified }) => {
	// having an default state when loaded in
	const [newsCategory, setNewsCategory] = useState('Cryptocurrency');

	const { data: cryptoNews } = useGetCryptoNewsQuery({
		newsCategory,

		// if in the home page the count will be 10 else 100
		count: simplified ? 6 : 12,
	});

	// getting the list of available cryto currencies
	const { data } = useGetCryptosQuery(100);

	if (!cryptoNews?.value) return 'Loading Data...';

	console.log(cryptoNews);
	return (
		<Row gutter={[24, 24]}>
			{!simplified && (
				<Col span={24}>
					<Select
						showSearch
						className="select-news"
						placeholder="Select a Crypto card"
						optionFilterProp="children"
						onChange={(value) => setNewsCategory(value)}
						filterOption={(input, option) =>
							option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
						}
					>
						<Option value="Cryptocurrency">Cryptocurrency</Option>
						{data?.data?.coins?.map((currency) => (
							<Option value={currency.name}>{currency.name}</Option>
						))}
					</Select>
				</Col>
			)}
			{cryptoNews.value.map((news, index) => (
				<Col xs={24} sm={12} lg={8} key={index}>
					<Card hoverable className="news-card">
						<a href={news.url} target="_blank" rel="noreferrer">
							<div className="news-image-container">
								<Title className="news-title" level={4}>
									{news.name}
								</Title>
								<img
									style={{ maxWidth: '200px', maxHeight: '100px' }}
									src={news?.image?.thumbnail?.contentUrl || demoImage}
									alt="crytocurrecy"
									srcset=""
								/>
							</div>
							<p>
								{news.description.length > 100
									? `${news.description.substring(0, 100)}...`
									: news.description}
							</p>
							<div className="provider-container">
								<div>
									<Avatar
										src={
											news.provider[0]?.image?.thumbnail?.contentUrl ||
											demoImage
										}
										alt="provider img"
									/>
									<Text className="provider-name">
										{news.provider[0]?.name}
									</Text>
								</div>
								<Text>
									&nbsp; {moment(news.dataPublished).startOf('ss').fromNow()}
								</Text>
							</div>
						</a>
					</Card>
				</Col>
			))}
		</Row>
	);
};

export default News;
